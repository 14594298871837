import React, { FC, useEffect, useState } from "react";
import { CoverPageHeaderProps } from "./../generated-types";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { AspectRatio, Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { imageSizes } from "~/foundation/Theme";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { PageHeaderBase, PageHeaderImageBase, PageHeaderLinksBase } from "~/foundation/SitecoreContent/generated-types";
import { PageThemeBase } from "~/foundation/Theme/generated-types";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { BreakingNews } from "~/foundation/Components/BreakingNews";

const CoverPageHeader: FC<CoverPageHeaderProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageHeaderBase & PageHeaderImageBase & PageHeaderLinksBase & PageThemeBase>();
	const [smallDeviceBgHeight, setSmallDeviceBgHeight] = useState("95dvh");
	const isRtl = sitecoreContext.custom.settings.isRtl;


	useEffect(() => {
		setSmallDeviceBgHeight(`${Math.floor(window.innerHeight * 0.95)}px`);
	}, []);

	const theme = usePageTheme(sitecoreContext);
	const pageHeaderSpacingNone = sitecoreContext.route?.fields?.pageHeaderSpacing?.value === "none";

	return (
		<Ribbon
			pt="0"
			pb={pageHeaderSpacingNone ? "0" : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			bg={theme.colors.ribbon.bg}
			color={theme.colors.ribbon.color}>
			<BreakingNews sitecoreContext={sitecoreContext} />
			<AspectRatio position="absolute" top="0" w="100%" h={[smallDeviceBgHeight, null, null, "95dvh"]} ratio={[2 / 3, null, 16 / 9]} sx={
				sitecoreContext.pageEditing ? {} : {
					'.LazyLoad': {
						height: "100%",
						width: "100%"
					},
					'&:after': {
						content: `""`,
						position: 'absolute',
						top: "0",
						right: '0',
						bottom: '0',
						left: '0',
						background: ["linear-gradient(69.09deg, rgba(0, 0, 0, 0.76) 31.16%, rgba(0, 0, 0, 0) 90.51%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, null, "linear-gradient(81.38deg, rgba(0, 0, 0, 0.8075) 34.59%, rgba(0, 0, 0, 0) 71.8%), linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 27.65%)", null, "linear-gradient(72.77deg, rgba(0, 0, 0, 0.855) 26.39%, rgba(0, 0, 0, 0) 83.78%, rgba(0, 0, 0, 0.27) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 27.65%)"],
						transform: isRtl ? "scaleX(-1)" : "",
					}
				}}>

				<sc.AdvanceImage
					fields={sitecoreContext.route?.fields}
					itemId={sitecoreContext.itemId}
					fieldName="image"
					srcSet={[imageSizes.m, imageSizes.l, imageSizes.xl]}
					sizes="100vw"
					sx={{
						position: "absolute",
						objectFit: "cover",
						objectPosition: "center center",
						minWidth: "100%",
						minHeight: "100%",
					}}
					lazy={false}
				/>
			</AspectRatio>
			<Box w="100%" h="100%" pt={["var(--chakra-sizes-headerHeightBase)", null, null, "var(--chakra-sizes-headerHeightLG)"]}>
				<Box className="breadcrumb--on-top">
					<sc.Placeholder name={rendering.placeholderNames.breadcrumb_navigation} rendering={rendering}/>
				</Box>
				<ContentWrapper position="relative" py="0" px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}>
					<Box minHeight="89dvh" pt={[null, null, "7.8125rem"]} px={[null, null, "8", null, "16"]} color="primary.white">
						<Flex
							direction="column" h="100%"
							justifyContent="center"
							mb={"16"}
						>
							{(sitecoreContext.route?.fields?.trumpet?.value || sitecoreContext.pageEditing) &&
								<Box maxW={["100%", null, "582"]} mb="6" pe={["4", null, "0"]}>
									<ChakraText variant="overline">
										<sc.Text field={sitecoreContext.route?.fields?.trumpet} />
									</ChakraText>
								</Box>
							}
							{(sitecoreContext.route?.fields?.headline?.value || sitecoreContext.pageEditing) &&
								<Box maxW={["100%", null, "582"]} pe={["4", null, "0"]}>
									<Heading as="h1" size="xxl" mb={["6", null, "8"]} wordBreak="normal">
										<sc.Text field={sitecoreContext.route?.fields?.headline} />
									</Heading>
								</Box>
							}
							{(sitecoreContext.route?.fields?.description?.value || sitecoreContext.pageEditing) &&
								<ChakraText
									as="span"
									display="block"
									size="xs"
									lineHeight="1.5"
									fontSize="body"
									mb={["6", null, "8"]}
									maxW={["100%", null, "582"]}
									pe={["4", null, "0"]}
								>
									<sc.RichText field={sitecoreContext.route?.fields?.description} />
								</ChakraText>
							}
						</Flex>
						<Box className="featured-article">
							<sc.Placeholder name={rendering.placeholderNames.page_header_featured} rendering={rendering}/>
						</Box>
					</Box>
				</ContentWrapper>
			</Box>
		</Ribbon>
	);
};

export default CoverPageHeader;